import { Search } from "lucide-react";
import { useState } from "react";
import { z } from "zod";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";
import { useToast } from "./ui/use-toast";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "./ui/select"
// Esquemas de validación para cada formulario
const CommunityFormSchema = z.object({
  communityInput: z.string().min(1, "Please enter a value"),
});
const SearchButton = ({href}) => {
  return(
    <a
    href={href}
    className="mt-[0px]"
  >
  <Button>
<Search className="mr-2 h-5 w-5 text-white" /> <p className="text-md font-semibold leading-5
">Search</p>
</Button>
  </a>

  )
}
const ItineraryFormSchema = z.object({
  itinerarySelect: z.string(),
});

// Componente de formulario para "community"
const CommunityForm = () => {
  const [inputValue, setInputValue] = useState("");
  const toast = useToast();

  // Manejador para actualizar el estado basado en el input
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
const  handleInputChange = (event : any) => {
    setInputValue(event.target.value);
  };


  return (
    <div className="flex items-center justify-center gap-4">
      <Input
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Type something..."
      />
      <SearchButton href={`/plan/${inputValue}`}/>
      </div>
     
  );
};

// Componente de formulario para "itinerary"
const ItineraryForm = () => {
  const [selectedValue, setSelectedValue] = useState("1");
  const [inputValue, setInputValue] = useState("");
  const toast = useToast();

  // Manejador para actualizar el estado basado en el input
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
const  handleInputChange = (event : any) => {
    setInputValue(event.target.value);
  };

  // Manejador para actualizar el estado basado en la selección
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
const  handleSelectChange = (event : any) => {
    setSelectedValue(event);
  };


  // ...

  return (
    <div className="w-full gap-4 flex items-center justify-center">
       <Input
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Type something..."
        className="grow-1"
      />
      <Select onValueChange={handleSelectChange} value={selectedValue}>
      <SelectTrigger className="w-[180px]">
        <SelectValue placeholder="Days" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>Fruits</SelectLabel>
          <SelectItem value="1">1</SelectItem>
          <SelectItem value="2">2</SelectItem>
          <SelectItem value="3">3</SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
    <SearchButton href={`/plan/${inputValue}?days=${selectedValue}`}/>
    </div>
  );
};

// Componente principal que incluye las pestañas
const SearchBar = () => {
  return (
    <div className="max-w-xl mx-auto h-auto rounded p-4">
      <Tabs defaultValue="community" className="w-full">
        <TabsList className="grid w-full grid-cols-3">
          <TabsTrigger value="community">Community</TabsTrigger>
          <TabsTrigger value="itinerary">Itinerary</TabsTrigger>
          <TabsTrigger value="highlights">Highlights</TabsTrigger>
        </TabsList>
        <TabsContent value="community">
          <CommunityForm />
        </TabsContent>
        <TabsContent value="itinerary">
          <ItineraryForm />
        </TabsContent>
        <TabsContent value="highlights">
          <CommunityForm />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default SearchBar;
