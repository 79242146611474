import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const getNestedContent = (response: string) => {
  const strSinTabulaciones = response.replace(/\t|\n/g, "");
  const trimResponse = strSinTabulaciones.trim();
  const expresionRegular: RegExp = /\s*\[\s*\[(.*?)\]\s*\]\s*/gs;
  const match = expresionRegular.exec(trimResponse);
  if (match) return JSON.parse(match[0]);
  return undefined;
};
